/* TitTap Editor Design */

.ProseMirror::-webkit-scrollbar {
  display: none;
}

.ProseMirror li {
  display: list-item;
}

.ProseMirror:focus {
  border: none;
  outline: none;
}

.ProseMirror p {
  margin: 0;
  padding: 0;
}

.ProseMirror > * + * {
  margin-top: 0.75em;
}

.ProseMirror ul,
.ProseMirror ol {
  padding: 0 2rem;
}

.ProseMirror h1,
.ProseMirror h2,
.ProseMirror h3,
.ProseMirror h4,
.ProseMirror h5,
.ProseMirror h6 {
  line-height: 1.1;
}

.ProseMirror code {
  background-color: rgba(#616161, 0.1);
  color: #616161;
}

.ProseMirror pre {
  background: #0d0d0d;
  color: #fff;
  font-family: 'JetBrainsMono', monospace;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
}
.ProseMirror code {
  color: inherit;
  padding: 0;
  background: none;
  font-size: 0.8rem;
}

.ProseMirror img {
  width: 30px;
  height: 30px;
  height: auto;
  cursor: pointer;
  vertical-align: text-bottom;
}

.ProseMirror blockquote {
  padding-left: 1rem;
  border-left: 3px solid #999999;
}

.ProseMirror hr {
  border: none;
  border-top: 3px solid #999999;
  margin: 2rem 0;
}

/* Placeholder  */
.ProseMirror p.is-editor-empty:first-child::before {
  color: #868686;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.ProseMirror-selectednode {
}

/* TASK LIST */
.ProseMirror ul[data-type='taskList'] li > label {
  flex: 0 0 auto;
  margin-right: 0.5rem;
  user-select: none;
}

.ProseMirror ul[data-type='taskList'] > div {
  flex: 1 1 auto;
}

.ProseMirror ul[data-type='taskList'] li > p {
  padding: 0;
}

.ProseMirror ul[data-type='taskList'] {
  list-style: none;
  padding: 0;
}
.ProseMirror ul[data-type='taskList'] li {
  display: flex;
}
.ProseMirror h5 {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-size: 1em;
  font-weight: normal;
  margin: 0;
}
